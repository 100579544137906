<script lang="ts">
	import { override } from '$src/utils/classnames'
	import { twMerge } from 'tailwind-merge'
	import ExIcon from '../icons/ExIcon.svelte'
	import LeftArrowIcon from '../icons/LeftArrowIcon.svelte'
	import RightArrowIcon from '../icons/RightArrowIcon.svelte'
	import { onMount } from 'svelte'

	let className: string = ''
	export { className as class }

	// let canGoBack = false
	// let canGoForward = false

	// function updateHistoryState() {
	// 	canGoBack = window.history.length > 1 // There's always at least one entry in history
	// 	// For forward state, we don't have a direct way to check, but we can use a workaround:
	// 	// We can push a new state and then go back immediately to check if the forward state becomes available
	// 	const currentState = window.history.state
	// 	window.history.pushState({}, '')
	// 	canGoForward = !!window.history.state
	// 	window.history.back()
	// 	window.history.replaceState(currentState, '')
	// }

	// const goBack = () => {
	// 	if (canGoBack) {
	// 		window.history.back()
	// 	}
	// }

	// const goForward = () => {
	// 	if (canGoForward) {
	// 		window.history.forward()
	// 	}
	// }

	// onMount(() => {
	// 	// Initial check
	// 	updateHistoryState()

	// 	// Update history state on navigation
	// 	window.addEventListener('popstate', updateHistoryState)

	// 	return () => {
	// 		window.removeEventListener('popstate', updateHistoryState)
	// 	}
	// })
</script>

<div
	class={twMerge(
		override(
			'sticky top-0 z-20 max-w-full w-full bg-white dark:bg-brand-gray-6 dark:lg:bg-brand-gray-5',
			className,
		),
	)}
>
	<div class="relative">
		<div
			class="h-[2px] w-10 rounded-full bg-brand-gray-4/50 mx-auto absolute lg:hidden top-[8px] right-[calc(50%-20px)]"
		/>

		<!-- <div class="flex justify-between items-center pb-1 pt-2 px-4 lg:pt-[10px]">
			<div class="flex space-x-2">
				<button on:click={goBack} class="flex items-center justify-center">
					<LeftArrowIcon
						class={`h-6 w-6 ${
							canGoBack
								? 'text-brand-gray-4/80 hover:text-white  cursor-pointer'
								: 'text-brand-gray-4/30 cursor-default'
						}  transition-colors`}
					/>
				</button>
				<button on:click={goForward} class="flex items-center justify-center">
					<RightArrowIcon
						class={`h-6 w-6 ${
							canGoForward
								? 'text-brand-gray-4/80 hover:text-white  cursor-pointer'
								: 'text-brand-gray-4/30 cursor-default'
						}  transition-colors`}
					/>
				</button>
			</div>
			<div class="flex items-end">
				<button class="flex items-center justify-center cursor-pointer">
					<ExIcon class="h-4 w-4 text-brand-gray-4/80 hover:text-white transition-colors" />
				</button>
			</div>
		</div> -->
		<div class="flex justify-between items-center py-4 px-4 lg:pt-6">
			<slot />
		</div>
	</div>
</div>
